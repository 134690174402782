/* --- MOBILE STYLES --- */

.projects{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: var(--spacing);
  padding-bottom: var(--spacing);
}
/* project card */
.project-card {
  display: flex;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 900px;
}
.project-card .card-content p {
    color:#495057;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
.project-card .card-content h4 {
  color: var(--hl-color);
  margin-top:20px;
  margin-left: 20px;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 15px;
  }
/* Individual project pages  */
.project-details{
    padding: 20px;
    max-width: 900px;

  }
  
  .project-details a {
    color: var(--hl-color);
  }
  .project-details a:hover {
    color: #e9c46a;
  }
  .project-details p {
      color: var(--secondary-text-color);
      font-size: 16px;
      margin-bottom: 20px;
    }
  .project-details h5 {
    color: var(--secondary-text-color);
    margin-top:20px;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
    }
  .project-details h4 {
    color: var(--hl-color);
    margin-top:20px;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
    }